import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom"
import { Container, Header } from 'semantic-ui-react'


export default Privacy = ->

  <table width='100%'>
    <tbody>
      <tr>
        <td height='50px'></td>
      </tr>
      <tr>
        <td></td>
        <td width='500px'>
          <Header as='h1'>Privacy Policy</Header>
          <p>
            YEAP.LA is an anonymous service, which means you do not need to have an account on the YEAP.LA network.
          </p>
          <Header as='h3'>We don’t track you. That’s our Privacy Policy in a nutshell.</Header>
          <p>
            Your configuration data is only saved on the client side, such as your smartphone, web browser.
          </p>
          <p>
            Your actions, such as star or heart on a video you like, discover or search for related videos, will be instantly shared with the community as an anonymous user.
          </p>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
