import React, {useCallback, useRef} from 'react'
import {proxy, snapshot, useSnapshot, subscribe} from 'valtio'
import {subscribeKey} from 'valtio/utils'
import { useNavigate, useLocation } from "react-router-dom"
import { Segment, MenuItem, Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, Input, Label, Icon, Button } from 'semantic-ui-react'
import localforage from "localforage"

now = ->
  (new Date()).getTime()

filterOptions = [
  {
    key: 'all',
    text: 'all',
    value: 'all',
    content: 'All'
  },
  {
    key: 'featured',
    text: 'featured',
    value: 'featured',
    content: 'Featured'
  },
  {
    key: 'hearted',
    text: 'hearted',
    value: 'hearted',
    content: 'Hearted'
  },
  {
    key: 'channels',
    text: 'channels',
    value: 'channels',
    content: 'Channels'
  },
  {
    key: 'playlists',
    text: 'playlists',
    value: 'playlists',
    content: 'Playlists'
  },
  {
    key: 'videos',
    text: 'videos',
    value: 'videos',
    content: 'Videos'
  },
  {
    key: 'shorts',
    text: 'shorts',
    value: 'shorts',
    content: 'Shorts'
  }
]

menuOptions = [
  {
    key: 'trending'
    text: 'Trending'
    value: 'trending'
    content: 'Trending'
  }
  {
    key: 'daily'
    text: 'Daily'
    value: 'daily'
    content: 'Daily'
  }
  {
    key: 'weekly'
    text: 'Weekly'
    value: 'weekly'
    content: 'Weekly'
  }
  {
    key: 'monthly'
    text: 'Monthly'
    value: 'monthly'
    content: 'Monthly'
  }
]


export qrHistoryStore = localforage.createInstance name: "qrHistoryStore"

export navbarState = proxy active: 'daily'
export qrcodeState = proxy qrValue: null
export filterState = proxy value: 'all'

subscribeKey navbarState, 'active', ->
  qrcodeState.qrValue = null

export default Navbar = ({filterStateValue, active, winWidth, marginLeft, padding}) ->
  inputRef = useRef null
  lastURL = useRef null
  navigate = useNavigate()
  {pathname} = window.location
  if pathname in ['/Org', '/My']
    active = null

  toggleMenu = useCallback ((current) ->
    ->
      navbarState.active = current
      if pathname in ['/Org', '/My']
        navigate '/'
  ), [navigate, pathname]


  onFilterChange = useCallback ((e, {value}) ->
    filterState.value = value
  ), []

  goOrg = ->
    navigate '/Org'

  goMy = ->
    navigate '/My'

  onBlur = onFocus = ->
    qrcodeState.qrValue = null
    inputRef.current?.value = ''

  onQRCodeChange = (e, data) ->
    console.log data.value
    qrcodeState.qrValue = data.value
    return unless data.value?.trim().length > 0
    return if lastURL.current? and 0 is lastURL.current.indexOf data.value
    lastURL.current = data.value
    try
        await qrHistoryStore.setItem now(), data.value
    catch e

  if winWidth >= 1920
    <div className='navbar' style={marginLeft: "#{marginLeft}px", padding: padding}>
      <Menu stackable borderless secondary size='massive'>
        <MenuItem>
          <img alt='logo' src='https://image.bbeeee.cloud/yeap.la/yeapla-outline.png' />
        </MenuItem>

        <MenuItem header>YEAP.LA</MenuItem>

        <MenuItem
          name='trending'
          active={active is 'trending'}
          onClick={toggleMenu 'trending'}
        >
          Trending
        </MenuItem>

        <MenuItem
          name='daily'
          active={active is 'daily'}
          onClick={toggleMenu 'daily'}
        >
          Daily
        </MenuItem>

        <MenuItem
          name='weekly'
          active={active is 'weekly'}
          onClick={toggleMenu 'weekly'}
        >
          Weekly
        </MenuItem>

        <MenuItem
          name='monthly'
          active={active is 'monthly'}
          onClick={toggleMenu 'monthly'}
        >
          Monthly
        </MenuItem>

        <MenuItem>
          <Input icon='qrcode' placeholder='URL to QR Code' onChange={onQRCodeChange}>
            <input ref={inputRef} onBlur={onBlur} onFocus={onFocus} />
          </Input>
        </MenuItem>

        <MenuMenu position='right'>
        {
          unless true or pathname in ['/Org', '/My']
            <Dropdown item trigger={<Icon name='filter' />} defaultValue={filterStateValue} options={filterOptions} onChange={onFilterChange} />
        }
          <MenuItem>
            <Button as='a' href="https://play.google.com/store/apps/details?id=la.yeap.app" basic icon>
              <Icon name='mobile alternate' /> GET APP
            </Button>
          </MenuItem>
          <MenuItem
            icon
            name='org'
            active={pathname is '/Org'}
            onClick={goOrg}
          >
            <Icon name='at' size='big' />
          </MenuItem>
          <MenuItem
            icon
            name='my'
            active={pathname is '/My'}
            onClick={goMy}
          >
            <Icon name='user circle outline' size='big' />
          </MenuItem>
        </MenuMenu>
      </Menu>
    </div>
  else
    <div style={padding: '30px'}>
      <Menu stackable borderless secondary size='massive'>
        <MenuItem>
          <img alt='logo' src='https://image.bbeeee.cloud/yeap.la/yeapla-outline.png' />
        </MenuItem>
        <MenuItem header>YEAP.LA</MenuItem>
      </Menu>
      <Menu stackable borderless secondary size='massive'>
        <Dropdown item defaultValue='daily' options={menuOptions} onChange={(e, {value}) -> navbarState.active = value} />
        {
          unless true or pathname in ['/Org', '/My']
            <Dropdown item trigger={<Icon name='filter' />} defaultValue={filterStateValue} options={filterOptions} onChange={onFilterChange} />
        }
      </Menu>
      <Menu stackable borderless secondary size='massive'>
        <MenuItem
          icon
          name='org'
          active={pathname is '/Org'}
          onClick={goOrg}
        >
          <Icon name='at' size='big' />
        </MenuItem>
        <MenuMenu>
          <MenuItem
            icon
            name='my'
            active={pathname is '/My'}
            onClick={goMy}
          >
            <Icon name='user circle outline' size='big' />
          </MenuItem>
        </MenuMenu>
      </Menu>
      <Segment basic>
        <Input
          size='big'
          placeholder='URL to QR Code'
          onChange={onQRCodeChange}
        >
          <input ref={inputRef} onBlur={onBlur} onFocus={onFocus} />
        </Input>
      </Segment>
      <Button as='a' href="https://play.google.com/store/apps/details?id=la.yeap.app" basic icon size='massive' fluid>
        <Icon name='mobile alternate' /> GET APP
      </Button>
    </div>
