import React, {createRef, useRef, useEffect, useCallback, useState} from 'react'
import { Link } from "react-router-dom"
import { MenuItem, Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, LabelGroup, Label, LabelDetail, Input, Icon, Button, Image,
  Header,
  Transition,
  Segment,
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card } from 'semantic-ui-react'
import {proxy, snapshot, useSnapshot, subscribe} from 'valtio'
import {subscribeKey} from 'valtio/utils'
import { XMasonry, XBlock } from "react-xmasonry"
import {useWindowSize} from 'react-use'
import QRCode from "react-qr-code"
import localforage from "localforage"
import shuffle from 'lodash.shuffle'
import delay, {rangeDelay, clearDelay} from 'delay'
import px1 from '1px'

import {navbarState, qrcodeState} from '../components/Navbar'
import {PageLoader} from '../components/Loader'

import invidious from '../utils/invidious'
import {featuredResult, trendingResult, feedResult, loadState} from '../utils/feed'

now = ->
  (new Date()).getTime()

oneDay = 24 * 60 * 60 * 1000

export starredStore = localforage.createInstance name: "starredStore"

export keywordsState = proxy {}

subscribe keywordsState, ->
  params = new URLSearchParams()
  snap = snapshot keywordsState
  unless Object.entries(snap).length > 0
    qrcodeState.qrValue = null
    return
  for keyword, type of snap
    if type is 'hashtag'
      params.append 'hashtag', keyword
    else
      params.append 'keyword', keyword
  qrcodeState.qrValue = "yeapla+search://tags?#{params}"

subscribeKey navbarState, 'active', (active) ->
  return if active is 'trending'
  for key in Object.keys snapshot keywordsState
    delete keywordsState[key]

feedFilter = (filterStateValue) ->
  (item) ->
    switch filterStateValue
      when 'featured'
        item.action is 'featured'
      when 'hearted'
        item.action is 'heart'
      when 'channels'
        if item.action is 'qr'
          item.type is 'channel'
        else
          item.action is 'channel'
      when 'playlists'
        if item.action is 'qr'
          item.type is 'playlist'
        else
          item.action is 'playlist'
      when 'videos'
        if item.action is 'qr'
          item.type is 'video'
        else
          item.action is 'video'
      when 'shorts'
        if item.action is 'qr'
          item.type is 'shorts'
        else
          item.action is 'shorts'
      else
        true

export Primary = ({filterStateValue}) ->
  {keyword, hashtag, policy, trending} = useSnapshot loadState

  useEffect (->
    do ->
      await invidious.updateInvidious()
    return
  ), []


  <div style={padding: '10px 50px', textAlign: 'center'}>
  {
    if true in [keyword.daily, keyword.weekly, keyword.monthly, hashtag.daily, hashtag.weekly, hashtag.monthly]
      <LabelGroup size='huge'>
      {
        list = [
          ...(feedResult.current.keyword or []),
          ...(feedResult.current.hashtag or [])
        ]
        .sort (a, b) -> b.ts - a.ts
        .sort (a, b) -> b.rank - a.rank
        list.map ({type, keyword}) ->
          <KeywordLabel key="#{type}-#{keyword}" type={type} keyword={keyword} />
      }
      </LabelGroup>
    else
      <PageLoader size={5} />
  }
  {
    if trendingResult.current?
      <XMasonry>
      {
        trendingResult.current.filter(feedFilter filterStateValue).map (item) ->
          <XBlock key="xb-trending-#{item.id}">
            <InfoCard {...item} />
          </XBlock>
      }
      </XMasonry>
    else
      <PageLoader />
  }
  </div>

KeywordLabel = ({type, keyword}) ->
  snap = useSnapshot keywordsState

  handleAddKeyword = (keyword, type) ->
    ->
      keywordsState[keyword] = type

  handleRemoveKeyword = (keyword, type) ->
    ->
      delete keywordsState[keyword]

  if type is 'hashtag'
    if snap[keyword]? and snap[keyword] is 'hashtag'
      <Label as='a' color='blue' basic onClick={handleRemoveKeyword keyword, type}>
        <Icon name='hashtag' />
        {keyword}
        <LabelDetail>
          <Icon name='check' />
        </LabelDetail>
      </Label>
    else
      <Label as='a' color='blue' onClick={handleAddKeyword keyword, type}>
        <Icon name='hashtag' />
        {keyword}
      </Label>
  else
    if snap[keyword]?
      <Label as='a' basic onClick={handleRemoveKeyword keyword, type}>
        {keyword}
        <LabelDetail>
          <Icon name='check' />
        </LabelDetail>
      </Label>
    else
      <Label as='a' onClick={handleAddKeyword keyword, type}>
        {keyword}
      </Label>

export Secondary = ({filterStateValue, active}) ->
  [reload, setReload] = useState 0
  # {featured, heart, video, shorts} = useSnapshot loadState
  # heartLoaded = heart[active]
  # videoLoaded = video[active]
  # shortsLoaded = shorts[active]
  useEffect (->
    subscribe loadState, ->
      setReload (n) -> n + 1
  ), []


  feedItems = [
    ...(feedResult.current.heart or []),
    ...(feedResult.current.video or []),
    ...(feedResult.current.shorts or [])
  ]

  if active is 'monthly'
    feedItems = feedItems.filter (item) ->
      (now() - 30 * oneDay) < item.ts <= (now() - 7 * oneDay)
  else if active is 'weekly'
    feedItems = feedItems.filter (item) ->
      (now() - 7 * oneDay) < item.ts <= (now() - oneDay)
  else
    feedItems = feedItems.filter (item) ->
      (now() - oneDay) < item.ts

  list = [
    ...(featuredResult.current or []),
    ...feedItems
  ].sort (a, b) -> b.ts - a.ts

  list = list.filter feedFilter filterStateValue

  useEffect (->
    do ->
      await invidious.updateInvidious()
    return
  ), [active]

  return <PageLoader /> unless snapshot(loadState).featured is true

  <XMasonry key="secondary-view-#{active}">
  {
    list.map (item, n) ->
      if item.action is 'featured'
        <XBlock key="xb-featured-#{item.tags.join('-')}-#{item.year}-#{item.month}-#{item.date}-#{item.type}-#{item.updatedAt}">
          <FeaturedCard {...item} />
        </XBlock>
      else
        <XBlock key="xb-#{item.id}">
          <InfoCard {...item} />
        </XBlock>
  }
  </XMasonry>

infoCardLabel =
  heart:
    icon: 'heart outline'
    color: 'red'
  video:
    icon: 'play circle outline'
    color: 'blue'
  shorts:
    icon: 'mobile alternate'
    color: 'black'
  channel:
    icon: 'tv'
    color: 'yellow'
  playlist:
    icon: 'unordered list'
    color: 'green'

export InfoCard = ({action, source, id, clients, ts}) ->
  [info, setInfo] = useState null
  imageURL = invidious.videoImageCache[id] or info?.image
  if not imageURL? and action in ['heart', 'video', 'shorts']
    imageURL = "https://img.youtube.com/vi/#{id}/maxresdefault.jpg"
  title = invidious.videoTitleCache[id] or info?.title or '**********'
  corner = 'left'
  {icon, color} = infoCardLabel[action]
  [starred, setStarred] = useState null

  useEffect (->
    do ->
      try
        if action in ['heart', 'video', 'shorts']
          setInfo await invidious.getVideoBasicInfo id
      catch e
    return
  ), [action, id]

  useEffect (->
    do ->
      item = await starredStore.getItem id
      setStarred item?
    return
  ), [id]

  onClick = ->
    qrcodeState.qrValue = "yeapla+action://#{action}/#{id}"

  onClickStar = useCallback (->
    setStarred not starred
    if starred is true
      await starredStore.removeItem id
    else
      await starredStore.setItem id, JSON.stringify {action, source, id, clients, ts}
  ), [starred, action, source, id, clients, ts]

  <div className='cardblock' style={width: '300px'}>
    <Card style={margin: '10px', textAlign: 'left'}>
      <CardContent>
        <Image src={imageURL} label={{corner, icon, color}} style={width: '100%', aspectRatio: (16 / 9), backgroundColor: '#f7f7f7'} />
      </CardContent>
      <CardContent>
        <CardDescription content={title} style={whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'} />
      </CardContent>
      <CardContent as='a' onClick={onClick}>
        <QRCode value={"yeapla+action://#{action}/#{id}"} />
      </CardContent>
      <CardContent as={Button} disabled={not starred?} onClick={onClickStar} basic icon>
      {
        if starred is true
          <Icon name='star' size='large' color='yellow' />
        else
          <Icon name='star outline' size='large' />
      }
      </CardContent>
    </Card>
  </div>

featuredTypesIcon = video: 'file video outline', shorts: 'mobile alternate', list: 'unordered list'

FeaturedCard = ({action, source, type, tags, category, year, month, date, updatedAt, videos}) ->
  key = "#{tags.join '-'}/#{year}/#{month}/#{date}"
  list = videos.join '\n'
  onClick = useCallback (->
    params = new URLSearchParams()
    for id in list.split('\n')
      params.append 'id', id
    qrcodeState.qrValue = "yeapla+featured://#{type}/#{key}?#{params}"
  ), [type, key, list]

  <div className='cardblock' style={width: '300px'}>
    <Card link onClick={onClick} style={margin: '10px', textAlign: 'left'}>
      <CardContent>
        <Label ribbon size='large'>
          <Icon name={featuredTypesIcon[type]} /> Featured
        </Label>
      </CardContent>
      {
      if type is 'list'
        <CardContent as='table' cellSpacing={1} cellPadding={0} border={0}>
          <tbody>
          {
            for n in [0...(Math.floor videos.length * 0.5)]
              <tr key="col-#{n}">
                <td>
                  <CardImage id={videos[n * 2]} />
                </td>
                <td>
                  <CardImage id={videos[n * 2 + 1]} />
                </td>
              </tr>
          }
          </tbody>
        </CardContent>
      else
        <CardContent>
        {
          if type is 'shorts'
            <CardMobileSlider videos={videos} />
          else if type is 'video'
            for id in videos
              <CardImage key="featured-card-image-#{id}" id={id} />
        }
        </CardContent>
      }
      <CardContent>
        <LabelGroup>
        {
          tags[1...].map (tag) ->
            <Label key="featured-card-label-#{tag}">{tag}</Label>
        }
        </LabelGroup>
      </CardContent>
      <CardContent as={Header} sub attached='top' textAlign='center'>{"#{year}-#{String(month).padStart 2, '0'}-#{String(date).padStart 2, '0'}"}</CardContent>
    </Card>
  </div>

CardImage = ({id}) ->
  [image, setImage] = useState null

  useEffect (->
    do ->
      {image} = await invidious.getVideoBasicInfo id
      if image?
        setImage image
    return
  ), []

  aspectRatio = 16 / 9
  backgroundImage = "url(#{image})"
  backgroundColor = "#f7f7f7"
  backgroundRepeat = 'no-repeat'
  backgroundPosition = 'center center'
  backgroundSize = 'cover'

  <Image fluid src={px1} style={{aspectRatio, backgroundImage, backgroundColor, backgroundRepeat, backgroundPosition, backgroundSize}} />

CardMobileSlider = ({videos}) ->
  [image, setImage] = useState null
  delayPromise = useRef null

  useEffect (->
    do ->
      while true
        for id in videos
          setImage null
          result = await invidious.getVideoBasicInfo id
          if result.image?
            setImage result.image
            delayPromise.current = rangeDelay 10000, 20000
            await delayPromise.current
    ->
      clearDelay delayPromise.current
  ), []

  aspectRatio = 9 / 16
  backgroundImage = "url(#{image})"
  backgroundColor = "#f7f7f7"
  backgroundRepeat = 'no-repeat'
  backgroundPosition = 'center center'
  backgroundSize = 'cover'

  <Transition visible={image?} animation='fade' duration={1000}>
    <Image fluid src={px1} style={{aspectRatio, backgroundImage, backgroundColor, backgroundRepeat, backgroundPosition, backgroundSize}} />
  </Transition>




CardThumbnail = ({videos}) ->

  <CardContent as='table' cellSpacing={1} cellPadding={0} border={0}>
    <tbody>
    {
      for n in [0...(Math.floor videos.length * 0.5)]
        <tr key="col-#{n}">
          <td>
            <CardImage id={videos[n * 2]} />
          </td>
          <td>
            <CardImage id={videos[n * 2 + 1]} />
          </td>
        </tr>
    }
    </tbody>
  </CardContent>
