import React from 'react'
import { Link } from "react-router-dom"
import {proxy, snapshot, useSnapshot, subscribe} from 'valtio'
import { MenuItem, Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, Input, Icon, Button } from 'semantic-ui-react'
import {useWindowSize} from 'react-use'
import QRCode from "react-qr-code"
import {useKey} from 'react-use'

import Navbar, {navbarState, qrcodeState} from '../components/Navbar'
import GetAppBanner from '../components/GetAppBanner'

xWidth = 3838
xHeight = 2004

export default Layout = ({filterStateValue, active, children}) ->
  useKey 'Escape', -> qrcodeState.qrValue = null
  {qrValue} = useSnapshot qrcodeState
  {width: winWidth, height: winHeight} = useWindowSize()
  siderbarWidth = (900 / xWidth ) * winWidth
  getappbannerMarginBottom = (100 / xHeight) * winHeight
  getappbannerBtnWidth = (500 / xWidth) * winWidth
  getappbannerBtnHeight = (150 / 500) * getappbannerBtnWidth
  navbarPadding = "20px #{getappbannerMarginBottom * 0.5}px"

  <div className='container' style={paddingLeft: "#{siderbarWidth}px"}>
    <div className='siderbar' style={width: "#{siderbarWidth}px"}>
      <img src="https://image.bbeeee.cloud/yeap.la/yeapla-splash.png" width="#{siderbarWidth}px" />
      <GetAppBanner width={siderbarWidth} btnWidth={getappbannerBtnWidth} btnHeight={getappbannerBtnHeight} marginBottom={getappbannerMarginBottom} />
    </div>
    <Navbar filterStateValue={filterStateValue} active={active} winWidth={winWidth} marginLeft={siderbarWidth} padding={navbarPadding} />
    {
      if winWidth >= 1920
        <div style={height: '120px'} />
    }
    {children}
    {
      if qrValue?.trim().length > 0
        <div style={position: 'fixed', zIndex: 9999, bottom: 0, left: "#{siderbarWidth}px", right: 0, height: "#{winHeight * 0.5}px", backgroundColor: '#ffffff', textAlign: 'center', paddingTop: "#{winHeight * 0.1}px"}>
          <QRCode value={qrValue} size={winHeight * 0.5 - 2 * winHeight * 0.1} />
        </div>
    }
  </div>
