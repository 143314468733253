import React, {createRef, useRef, useState, useEffect, useCallback} from 'react'
import { Link } from "react-router-dom"
import { MenuItem, Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, Input, Icon, ButtonGroup, Button, Image,
  Segment,
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card } from 'semantic-ui-react'
import { XMasonry, XBlock } from "react-xmasonry"
import {useWindowSize} from 'react-use'
import QRCode from "react-qr-code"
import localforage from "localforage"
import getVideoId from 'get-video-id'
import {subscribeKey} from 'valtio/utils'

import {starredStore, InfoCard} from '../components/TrendingView'
import Layout from '../components/Layout'
import {qrHistoryStore, navbarState, qrcodeState} from '../components/Navbar'
import invidious from '../utils/invidious'

export default My = ->
  [view, setView] = useState 0

  toggleView = useCallback (->
    if view > 0
      setView 0
    else
      setView 1
  ), [view]

  <Layout>
    <Segment basic textAlign='center'>
      <Button onClick={toggleView} disabled={view isnt 1} basic>QR Codes</Button>
      <Button onClick={toggleView} disabled={view is 1} basic>My Starred</Button>
    </Segment>
    {
    if view is 1
      <StarredView />
    else
      <QRCodesView />
    }
  </Layout>

StarredView = ->
  [list, setList] = useState null
  console.log list
  useEffect (->
    do ->
      results = []
      await starredStore.iterate (value) ->
        results.push JSON.parse value
        return
      setList results
    return
  ), []

  <XMasonry>
  {
    list?.map (item) ->
      <XBlock key="xb-#{item.id}">
        <InfoCard {...item} />
      </XBlock>
  }
  </XMasonry>

QRCodesView = ->
  [reload, setReload] = useState true
  qrvalues = useRef []

  useEffect (->
    qrcodeState.qrValue = null
    subscribeKey qrcodeState, 'qrValue', ->
      setReload true
  ), []

  useEffect (->
    return unless reload is true
    do ->
      keys = await qrHistoryStore.keys()
      values = []
      valueSet = new Set
      for key in keys
        value = await qrHistoryStore.getItem key
        continue unless value?.trim().length > 0
        continue if valueSet.has value
        valueSet.add value
        ts = parseInt key
        values.push {ts, value}
      values.sort (a, b) -> b.ts - a.ts
      qrvalues.current = values
      setReload false
    return
  ), [reload]

  handleRemove = (key) ->
    ->
      qrHistoryStore.removeItem key
      setReload true

  <XMasonry>
  {
    qrvalues.current.map ({ts, value}) ->
      dt = new Date ts
      <XBlock key="xb-qrvalue-#{value}">
        <div className='cardblock' style={width: '300px'}>
          <Card style={margin: '10px'}>
            <CardInfo url={value} />
            <CardContent as='a' onClick={->qrcodeState.qrValue = value}>
              <QRCode value={value} />
            </CardContent>
            <CardContent>
              <CardMeta>
                <span>Created at {dt.toLocaleString()}</span>
              </CardMeta>
            </CardContent>
            <CardContent extra>
              <Button fluid color='red' onClick={handleRemove "#{ts}"}>
                <Icon name='trash' />
                Remove
              </Button>
            </CardContent>
          </Card>
        </div>
      </XBlock>
  }
  </XMasonry>


CardInfo = ({url}) ->
  [info, setInfo] = useState null
  {title, image} = info or {}
  console.log info

  useEffect (->
    do ->
      {id, service} = await getVideoId url
      return unless service is 'youtube' and id?
      {title, image} = await invidious.getVideoBasicInfo id
      image ?= "https://img.youtube.com/vi/#{id}/maxresdefault.jpg"
      setInfo {title, image}
    return
  ), []

  <>
    <CardContent>
    {
      if title?
        <CardHeader content={title} />
    }
      <CardDescription content={url} />
    </CardContent>
    {
      if image?
        <CardContent>
          <Image fluid src={image} />
        </CardContent>
    }
  </>
