import React from 'react'
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import Home from './pages/Home'
import Org from './pages/Org'
import My from './pages/My'
import Privacy from './pages/Privacy'

router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  }
  {
    path: "/Org"
    element: <Org />
  }
  {
    path: "/My"
    element: <My />
  }
  {
    path: "/privacy"
    element: <Privacy />
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  )
