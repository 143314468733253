import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom"
import { MenuItem, Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, Input, Icon, ButtonGroup, Button, Image,
  Segment,
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card } from 'semantic-ui-react'
import { XMasonry, XBlock } from "react-xmasonry"
import {useWindowSize} from 'react-use'
import QRCode from "react-qr-code"
import localforage from "localforage"

import Layout from '../components/Layout'
import {navbarState, qrcodeState} from '../components/Navbar'
import orgs from '../data/orgs'

orgList = orgs.filter (org) -> org.stage is 'released'

export default Org = ->

  useEffect (->
    qrcodeState.qrValue = null
    return
  ), []

  handleClick = (name) ->
    ->
      qrcodeState.qrValue = "yeapla+org://#{name}/latest"

  <Layout>
    <XMasonry>
    {
      orgList.map ({name, domain, title, description}) ->
        if name is 'g63gg'
          description = description.split('(BTW')[0]
        <XBlock key="qrvalue-#{name}">
          <div className='cardblock' style={width: '300px'}>
            <Card basic link onClick={handleClick name} style={margin: '10px', textAlign: 'left'}>
              <CardContent>
                <Image src="https://image.bbeeee.cloud/#{domain}/#{name}-org-profile.png" />
              </CardContent>
              <CardContent>
                <CardHeader content={domain} />
                <CardDescription content={description} />
              </CardContent>
              <CardContent>
                <QRCode value="yeapla+org://#{name}/latest" />
              </CardContent>
            </Card>
          </div>
        </XBlock>
    }
    </XMasonry>
  </Layout>
