import localforage from "localforage"
import getYoutubeTitle from 'get-youtube-title'

class Invidious
  constructor: ->
    @babySharkDanceVideoId = 'XqZsoesa55w'
    @invidious = instances: [], updateTime: 0, primaryUri: 'https://invidious.jing.rocks', updating: false
    @videoTitleCache = {}
    @videoImageCache = {}
    @videoInfo = localforage.createInstance name: 'videoInfo'
  getIVDashUri: (id, uri) ->
    uri ?= @invidious.primaryUri
    console.log getIVDashUri: uri
    "#{uri}/api/manifest/dash/id/#{id}"
  updateInvidiousInstances: ->
    url = 'https://api.invidious.io/instances.json?sort_by=health,users'
    response = await fetch url
    if response.ok is true
      instances = await response.json()
      filterResults = (item)->
        {type, api, cors} = item[1]
        type is 'https' and api in [null, true] and cors in [null, true]
      instances = instances.filter(filterResults).map (item) -> item[1]
      instances.sort (a, b) ->
        au = a.stats?.usage?.users?.total or 0
        bu = b.stats?.usage?.users?.total or 0
        au - bu
      instances = uniqBy [...@invidious.instances, ...instances], ({uri}) -> uri
      @invidious.instances = instances
      @invidious.updateTime = now()
  updatePrimaryInstanceUri: ->
    blocked = new Set ['youtube.owacon.moe']
    primaryUri = await Promise.race @invidious.instances[0..5].map ({uri}) =>
      fetch @getIVDashUri(@babySharkDanceVideoId, uri)
      .then (response) ->
        if response.ok is true
          response.text()
          .catch ->
            delay 10 * 1000
            .then ->
              null
          .then ->
            {hostname} = new URL uri
            unless blocked.has hostname
              uri
            else
              delay 10 * 1000
              .then ->
                null
        else
          delay 10 * 1000
          .then ->
            null
    return unless primaryUri?.length > 0 and 0 is primaryUri.indexOf 'http'
    @invidious.primaryUri = primaryUri
    @invidious.primaryUriUpdateTime = now()
  updateInvidious: ->
    try
      return if @invidious.updating is true
      return if now() - @invidious.updateTime < 60 * 60 * 1000 and now() - @invidious.primaryUriUpdateTime < 60 * 1000
      @invidious.updating = true
      try
        await Promise.race [@updateInvidiousInstances(), delay 10000] unless now() - @invidious.updateTime < 60 * 60 * 1000
      catch e
      try
        await Promise.race [@updatePrimaryInstanceUri(), delay 10000] unless now() - @invidious.primaryUriUpdateTime < 60 * 1000
      catch e
      @invidious.updating = false
    catch e
    finally
      @invidious.updating = false
    console.log @invidious
  fetchJSON: (url) ->
    @updateInvidious()
    fetch url
    .then (response) ->
      response.json()
  getURLMeta: (url) ->
    @fetchJSON "https://sacqp5xed0.execute-api.us-east-1.amazonaws.com/meta?basic&url=#{url}"
  getVideoInfo: (id) ->
    @fetchJSON "#{@invidious.primaryUri}/api/v1/videos/#{id}"
  getVideoBasicInfo: (id) ->
    title = null
    image = null
    description = null
    info = await @videoInfo.getItem id
    if info?
      return JSON.parse info
    # if @videoTitleCache[id]?
    #   return title: @videoTitleCache[id], image: @videoImageCache[id]
    try
      info = await @getVideoInfo id
    catch e
      info = error: e
    if not info? or info.error?
      title = await new Promise (resolve) ->
        getYoutubeTitle id, (err, result) ->
          if err?
            resolve null
          else
            resolve result
      unless title?
        {success, result} = await @getURLMeta "https://www.youtube.com/watch?v=#{id}"
        if success is true
          {title, image, description} = result
    else
      title = info.title
      image = info.videoThumbnails[0].url
    # if title?
    #   @videoTitleCache[id] = title
    # if image?
    #   @videoImageCache[id] = image
    info = {title, image, description}
    if info.title?
      await @videoInfo.setItem id, JSON.stringify info
    info
  getChannelInfo: (id) ->
    @fetchJSON "#{@invidious.primaryUri}/api/v1/channels/#{id}"
  getChannelVideos: (id) ->
    @fetchJSON "#{@invidious.primaryUri}/api/v1/channels/#{id}/videos"
  getChannelShorts: (id) ->
    @fetchJSON "#{@invidious.primaryUri}/api/v1/channels/#{id}/shorts"
  getPlaylistInfo: (id) ->
    @fetchJSON "#{@invidious.primaryUri}/api/v1/playlists/#{id}"


export default new Invidious()
