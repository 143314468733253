import React, {createRef, useEffect, useCallback, useState} from 'react'
import {snapshot, useSnapshot} from 'valtio'

import Layout from '../components/Layout'
import {trendingMapRef, Primary, Secondary} from '../components/TrendingView'
import {navbarState, qrcodeState, filterState} from '../components/Navbar'

export default Home = ->
  {active} = useSnapshot navbarState
  {value: filterStateValue} = useSnapshot filterState

  useEffect (->
    qrcodeState.qrValue = null
    return
  ), []

  <Layout filterStateValue={filterStateValue} active={active}>
  {
  if active is 'trending'
    <Primary filterStateValue={filterStateValue} />
  else
    <Secondary filterStateValue={filterStateValue} active={active} />
  }
  </Layout>
