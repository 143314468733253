import React from 'react'
import {
  CardGroup,
  CardContent,
  Card,
  PlaceholderImage,
  PlaceholderParagraph,
  PlaceholderLine,
  PlaceholderHeader,
  Placeholder,
  GridColumn,
  Grid,
  Segment,
} from 'semantic-ui-react'
import { XMasonry, XBlock } from "react-xmasonry"

export PageLoader = ({size = 100}) ->

  <div style={padding: '0px 30px'}>
    <XMasonry>
    {
      [0...size].map (n) ->
        <XBlock key="placeholder-block-#{n}">
          <div className='cardblock'>
            <Card>
              <CardContent>
                <Placeholder>
                  <PlaceholderImage rectangular />
                  <PlaceholderHeader image>
                    <PlaceholderLine length='full' />
                    <PlaceholderLine length='full'/>
                  </PlaceholderHeader>
                </Placeholder>
              </CardContent>
            </Card>
          </div>
        </XBlock>
    }
    </XMasonry>
  </div>
