import React, {createRef} from 'react'
import {proxy, snapshot, useSnapshot, subscribe} from 'valtio'

export featuredResult = createRef()
export trendingResult = createRef()
export feedResult = createRef()
feedResult.current = heart: null, video: null, shorts: null, qrVideo: null, qrShorts: null, qrChannel: null, qrPlaylist: null, keyword: null, hashtag: null, policy: null
export loadState = proxy featured: null, trending: null
for key in Object.keys feedResult.current
  loadState[key] = daily: null, weekly: null, monthly: null

now = ->
  (new Date()).getTime()

apiEndpoint = 'https://sacqp5xed0.execute-api.us-east-1.amazonaws.com/'

fetchJSON = (url) ->
  fetch url
  .then (response) ->
    response.json()

export requestAPI = (method) ->
  try
    {success, result} = await fetchJSON "#{apiEndpoint}#{method}"
    if success is true
      loadState[method] = true
      result
    else
      loadState[method] = false
      null
  catch e
    loadState[method] = false
    null

export getFeeds = (filter = 'daily') ->
  Promise.all Object.keys(feedResult.current).map (type) ->
    fetchJSON "#{apiEndpoint}feed?type=#{if type[0..1] is 'qr' then 'qr%2F' + type[2...].toLowerCase() else type}&filter=#{filter}"
    .then ({success, result}) ->
      if success is true
        currentResult = feedResult.current[type] or []
        feedResult.current[type] = [...currentResult, ...result]
        grouped = Object.groupBy feedResult.current[type], ({id}) -> id
        feedResult.current[type] = Object.values(grouped).map (items) -> items[0]
        loadState[type][filter] = true

export load = ->
  [result1, result2] = await Promise.all [requestAPI('featured'), requestAPI('trending')]
  featuredResult.current = result1.filter ({ts}) -> ts <= now()
  trendingResult.current = result2
  for filter in ['daily', 'weekly', 'monthly']
    await getFeeds filter

do ->
  await load()
